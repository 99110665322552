import React from "react";
import imgPersonales from "../assets/images/personales.webp";
import imgOrdenes from "../assets/images/ordenes.webp";
import imgMicrofinanzas from "../assets/images/microfin.webp";
import imgCombinadas from "../assets/images/combinadas.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEarth,
  faEnvelope,
  faEnvelopeCircleCheck,
  faEnvelopeSquare,
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <section id="services" className="services">
      <h2>Nuestros Servicios</h2>
      <div className="service-cards">
        <div className="service-card">
          <img className="img" src={imgPersonales} alt="Préstamos Personales" />
          <div className="description">
            <h3>PRÉSTAMOS PERSONALES</h3>
            <p>En efectivo, cuotas mensuales o de pago único</p>
          </div>
          <div className="content">
            <ul>
              <li>Hasta 36 meses</li>
              <li>Sin edad límite</li>
            </ul>
            <ul>
              <li>Solicitud online</li>
              <li>Simple y rápido</li>
            </ul>
            <button
              className="cta-button"
              onClick={() => (window.location.href = "/contacto")}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              Solicitalo ahora!
            </button>
          </div>
        </div>
        <div className="service-card">
          <img
            className="img"
            src={imgCombinadas}
            alt="Ordenes de Compra Combinadas"
          />
          <div className="description">
            <h3>ORDEN + EFECTIVO</h3>
            <p>Compra el pack que más se adapte a tu necesidad + efectivo</p>
          </div>
          <div className="content">
            <ul>
              <li>Productos de elaboración artesanal de Nueva Helvecia</li>
              <li>Set de belleza personal </li>
            </ul>
            <ul>
              <li>Surtido del mes Artículos de limpieza</li>
              <li>Promo día del padre con campera abrigadísima de regalo</li>
            </ul>
            <button
              className="cta-button"
              onClick={() => (window.location.href = "/contacto")}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faEarth} />
              Solicitalo online!
            </button>{" "}
          </div>
        </div>
        <div className="service-card">
          <img className="img" src={imgMicrofinanzas} alt="Microfinanzas" />
          <div className="description">
            <h3>MICROFINANZAS</h3>
            <p>
              Pequeños productores, microemprendedores, micro y pequeñas
              empresas
            </p>
          </div>
          <div className="content">
            <button
              className="cta-button"
              onClick={() => (window.location.href = "/contacto")}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faEnvelopeSquare} />
              Contáctanos
            </button>{" "}
          </div>
        </div>
        <div className="service-card">
          <img className="img" src={imgOrdenes} alt="Órdenes de compra" />
          <div className="description">
            <h3>ÓRDENES DE COMPRA</h3>
            <p>
              Financiamos electrodomésticos, muebles, vestimenta y rodados,
              hasta en 7 cuotas sin recargo!
            </p>
          </div>
          <div className="content">
            <button
              className="cta-button"
              onClick={() => (window.location.href = "/contacto")}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
              Pedilo online!
            </button>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
