import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEnvelopeSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <>
    <section id="contact" className="contact-footer">
      <h2>Contáctanos</h2>
      <p>¿Tienes preguntas? ¡Estamos aquí para ayudarte!</p>
      <a href="mailto:info@credilit.com.uy" className="contact-button">
        <FontAwesomeIcon icon={faEnvelope} />
        Escríbenos vía e-mail
      </a>
      <a href="/contacto" className="contact-button">
        <FontAwesomeIcon icon={faEnvelopeSquare} />
        Contáctanos
      </a>
      <a href="https://wa.me/+59893679014" className="contact-button">
        <FontAwesomeIcon icon={faWhatsapp} />
        <span>093 679 014</span>
      </a>
    </section>
    <footer className="footer">
      <p>© 2024 Credilit. Todos los derechos reservados | </p>{" "}
      <a href="https://www.benjaminlabs.com" target="_blank" rel="noreferrer">
        Diseño por BenjaminLabs / Startupp.ai
      </a>
      <br />
      <a href="/">Inicio</a>
      <a href="/terminos">Términos y Condiciones</a>
      <a href="http://www.bcu.gub.uy" target="_blank" rel="noreferrer">
        BCU
      </a>
    </footer>
  </>
);

export default Footer;
