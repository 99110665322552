import React, { useEffect } from "react";
import Hero from "../components/Hero";

const About = () => {
  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Define mobile as width <= 768px
    const scrollDistance = isMobile ? 250 : 500; // Scroll less on mobile

    window.scrollTo({
      top: scrollDistance,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="home">
      <Hero />

      <section id="services" className="services content">
        <div className="history-cards">
          <div className="history-card">
            <main>
              <section id="history">
                <h2>Nuestra Historia</h2>
                <p>
                  La empresa fue fundada el 6 de abril de 1981, en la ciudad de
                  Fray Bentos, departamento de Río Negro y actualmente cuenta
                  con presencia en los Departamentos de Canelones, Montevideo y
                  Soriano.
                </p>
                <p>
                  Con Casa Matriz en 18 de Julio 1139, Fray Bentos, Río Negro.
                </p>
              </section>

              <section id="mission-vision">
                <h2>Misión</h2>
                <p>
                  Somos una empresa rentable, con personal comprometido que
                  entiende y satisface las necesidades de sus clientes,
                  ofreciéndoles productos y servicios financieros que fomentan
                  su bienestar y desarrollo, en una forma accesible, ágil y
                  oportuna.
                </p>

                <h2>Visión</h2>
                <p>
                  Ser una empresa líder en el segmento de créditos al consumo y
                  micro-finanzas, caracterizada por su solidez, profesionalidad
                  y crecimiento; orientada a la atención y servicio al cliente.
                </p>
              </section>

              <section id="objectives">
                <h2>Objetivos</h2>
                <ul>
                  <li>
                    Brindar asistencia a nuestros clientes en forma profesional
                    ofreciendo un servicio personalizado y calificado. Ocupando
                    de esta forma un espacio que cubra las necesidades de una
                    verdadera inclusión financiera diferente y original desde
                    nuestro interior para todo el Uruguay.
                  </li>
                  <li>
                    Desarrollar a nivel nacional desde el Litoral instrumentos
                    financieros innovadores, sin excluir ningún sector, basado
                    en nuestra Responsabilidad Social Empresaria que redunde en
                    el bienestar familiar.
                  </li>
                  <li>
                    Propiciar el mejoramiento económico y el acceso a los nuevos
                    servicios financieros son los pilares fundamentales de
                    nuestra empresa, fomentar las buenas conductas del crédito
                    responsable, estimular una profunda vocación en la educación
                    financiera de nuestros clientes.
                  </li>
                  <li>
                    Conceder atención crediticia a individuos que están
                    comprometidos es uno de nuestros mayores desafíos, para
                    lograr la inserción al circuito crediticio y así poder
                    obtener el bienestar que merecen junto a su entorno
                    familiar.
                  </li>
                  <li>
                    Contribuir a salir del endeudamiento de nuestros clientes
                    ayudará sin dudas en muchos aspectos. Devolver la confianza,
                    credibilidad frente al crédito, ayudará a lograr
                    responsablemente personas con perspectivas futuras muy
                    diferentes.
                  </li>
                </ul>
              </section>
            </main>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
