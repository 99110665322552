import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";

const Contact = () => {
  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Define mobile as width <= 768px
    const scrollDistance = isMobile ? 250 : 500; // Scroll less on mobile

    window.scrollTo({
      top: scrollDistance,
      behavior: "smooth",
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    cedula: "",
    service: "",
    edad: "",
    celular: "",
    direccion: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("https://formspree.io/f/mpwzlyjq", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      alert(
        "Tu mensaje ha sido enviado. Nos contactaremos contigo a la brevedad."
      );
      setFormData({
        name: "",
        email: "",
        message: "",
        cedula: "",
        service: "",
        edad: "",
        celular: "",
        direccion: "",
      });
    } else {
      alert(
        "El mensaje no pudo ser enviado. Por favor, inténtalo de nuevo, o contáctanos vía teléfono."
      );
    }
  };

  return (
    <>
      <Hero />
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <h2>Contáctanos</h2>
          <p>Llena el formulario para que podamos ayudarte</p>
          <div className="form-group">
            <label htmlFor="name">Nombre Completo:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cedula">Cédula:</label>
            <input
              type="text"
              id="cedula"
              name="cedula"
              value={formData.cedula}
              onChange={handleChange}
              placeholder="EJ: 1.234.567-8"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cedula">Edad:</label>
            <input
              type="text"
              id="edad"
              name="edad"
              value={formData.edad}
              onChange={handleChange}
              placeholder=""
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cedula">Dirección:</label>
            <input
              type="text"
              id="direccion"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              placeholder="18 de Julio 1139, Fray Bentos"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="celular">Celular / Móvil</label>
            <input
              type="text"
              id="celular"
              name="celular"
              value={formData.celular}
              onChange={handleChange}
              placeholder="093 111 222"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="service">Servicio:</label>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Selecciona un servicio
              </option>
              <option value="Préstamo Personal">Préstamo Personal</option>
              <option value="Pyme">Pyme</option>
              <option value="Órdenes de Compra">Órdenes de Compra</option>
              <option value="Otro Servicio">Otro Servicio</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Monto deseado, plazo, y cualquier otro detalle que consideres importante para agilizar el trámite."
              required
            ></textarea>
          </div>
          <button type="submit" className="cta-button">
            Enviar Consulta
          </button>
        </form>
      </div>
    </>
  );
};

export default Contact;
