import React, { useEffect } from "react";
import Services from "../components/Services";
import Hero from "../components/Hero";

const Servicios = () => {
  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Define mobile as width <= 768px
    const scrollDistance = isMobile ? 250 : 500; // Scroll less on mobile

    window.scrollTo({
      top: scrollDistance,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="home">
      <Hero />

      <Services />
    </div>
  );
};

export default Servicios;
